import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MagicTranslateService } from './app-services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private magicTranslateService: MagicTranslateService,
  ) {
    iconRegistry.addSvgIconResolver((name: string): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
      return sanitizer.bypassSecurityTrustResourceUrl(`assets/mat-icons/${name}.svg`);
    });
  }

  ngOnInit() {
    this.magicTranslateService.initLanguage();
    const preloader = document.querySelector('.preloader');
    if (preloader && preloader.parentNode) {
      preloader.parentNode.removeChild(preloader);
    }
  }
}
