<mat-toolbar>
  <form [formGroup]="loggingFiltreForm" novalidate>
    <mat-form-field class="admin-form-field" [subscriptSizing]="'dynamic'">
      <mat-chip-grid formControlName="admins" #adminsList aria-label="Admins selection">
        @for (admin of loggingFiltreForm.get('admins').value; track admin) {
          <mat-chip-row class="chip" [value]="admin" (removed)="remove(admin)">
            {{ admin.fullName }}
            <button matChipRemove>
              <mat-icon svgIcon="clear"></mat-icon>
            </button>
          </mat-chip-row>
        }

        <input
          matInput
          [placeholder]="'admins' | translate"
          #adminInput
          formControlName="adminSearch"
          [matAutocomplete]="auto"
          [matChipInputFor]="adminsList"
        />
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        @for (admin of filteredAdmins | async; track admin) {
          <mat-option [value]="admin">
            {{ admin.fullName }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <input
        matInput
        name="fDate"
        [max]="maxFirstDate"
        formControlName="startDate"
        [matDatepicker]="sDate"
        [placeholder]="'start-date' | translate"
        #firstDate
      />
      @if (loggingFiltreForm.controls['startDate'].hasError('date')) {
        <mat-error> {{ 'date' | translate }} </mat-error>
      }
      <mat-datepicker-toggle matSuffix [for]="sDate"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #sDate></mat-datepicker>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <input
        matInput
        name="lDate"
        [min]="minLastDate"
        [max]="maxLastDate"
        formControlName="endDate"
        [matDatepicker]="eDate"
        [placeholder]="'end-date' | translate"
        #lastDate
      />
      @if (loggingFiltreForm.controls['endDate'].hasError('date')) {
        <mat-error> {{ 'date' | translate }} </mat-error>
      }
      <mat-datepicker-toggle matSuffix [for]="eDate"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #eDate></mat-datepicker>

    <button mat-mini-fab color="accent" (click)="search(loggingFiltreForm.value)">
      <mat-icon svgIcon="search"></mat-icon>
    </button>
  </form>
</mat-toolbar>
