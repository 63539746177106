<mat-toolbar>
  <form [formGroup]="userFilterForm" novalidate>
    <mat-form-field class="user-form-field" [subscriptSizing]="'dynamic'">
      <mat-chip-grid class="chips-list" formControlName="users" #usersList aria-label="Users selection">
        @for (user of userFilterForm.get('users').value; track user) {
          <mat-chip-row class="chip" [value]="user" (removed)="remove(user)">
            {{ user.fullName }}
            <button matChipRemove>
              <mat-icon svgIcon="clear"></mat-icon>
            </button>
          </mat-chip-row>
        }
        <input
          matInput
          #userInput
          formControlName="userSearch"
          [placeholder]="'users' | translate"
          [matAutocomplete]="auto"
          [matChipInputFor]="usersList"
        />
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        @for (user of users; track user) {
          <mat-option [value]="user">
            {{ user.fullName }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <button mat-mini-fab color="accent" (click)="search(userFilterForm.value)">
      <mat-icon svgIcon="search"></mat-icon>
    </button>
  </form>
  <div class="add-btn">
    @if (isAdmin) {
      <a mat-raised-button color="accent" (click)="onAddNewUser()"> <mat-icon svgIcon="person_add"></mat-icon>{{ 'add' | translate }} </a>
    }
  </div>
</mat-toolbar>
