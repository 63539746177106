import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { AuthService, HandleErrorsService } from '../app-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent {
  loading = new ReplaySubject<boolean>(1);

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected snackBar: MatSnackBar,
    protected authService: AuthService,
    protected handleErrors: HandleErrorsService,

    protected translateService: TranslateService,
  ) {}

  resetPassword(newPassword: string) {
    this.loading.next(false);
    const scope = { ADMIN: 'admin', SUPER_ADMIN: 'super-admin', MODEL_ADMIN: 'model-admin' };

    this.authService.resetPassword({ hash: this.route.snapshot.params['hash'], newPassword: newPassword }).subscribe({
      next: response => {
        this.loading.next(true);
        this.router.navigate(['/login'], { queryParams: { scope: scope[response.data['role']] } });
        this.translateService.get('passwor-message-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
      },
      error: err => {
        this.loading.next(true);
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
