import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReplaySubject } from 'rxjs';
import { AuthService, HandleErrorsService } from '../app-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestPasswordResetComponent {
  loading = new ReplaySubject<boolean>(1);

  constructor(
    private auth: AuthService,
    private snackBar: MatSnackBar,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
  ) {}

  requestPasswordReset(value: { email: string; role: string }) {
    this.loading.next(false);

    const scope = { Admin: 'ADMIN', SuperAdmin: 'SUPER_ADMIN', ModelAdmin: 'MODEL_ADMIN' };
    const role = scope[value.role];

    this.auth.requestPasswordReset({ ...value, role }).subscribe({
      next: () => {
        this.loading.next(true);
        this.translateService.get('send-email').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
      },
      error: err => {
        this.loading.next(true);
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
