import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthStateService, Credentials } from './auth-state.service';
import { AsyncValidatorFn, FormControl } from '@angular/forms';
import { Role } from 'app/models/loggin.model';

@Injectable()
export class AuthService {
  credentials: Credentials = null;
  scope = '';

  constructor(
    private _http: HttpClient,
    private authState: AuthStateService,
    private router: Router,
  ) {
    this.credentials = this.authState.getCredentials();
    if (this.credentials) {
      this.scope = this.credentials.payload.role;
    }
    const queryParam = { ADMIN: 'admin', SUPER_ADMIN: 'super-admin', MODEL_ADMIN: 'model-admin' };

    this.authState.credentialsListner.subscribe(res => {
      this.credentials = res;
      if (res !== null) {
        this.scope = res.payload.role;
      } else {
        this.router.navigate([{ outlets: { dialog: null, primary: ['login'] } }], { queryParams: { scope: queryParam[this.scope] } });
      }
    });
  }

  loginAdmin(data: { email: string; password: string }): Observable<{ data: Credentials; statusCode: number }> {
    return this._http.post('/api/auth/admin/login', data, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Credentials; statusCode: number }>) => {
        this.authState.setCredentials(res.body.data);

        return res.body;
      }),
    );
  }

  loginSuperAdmin(data: { email: string; password: string }): Observable<{ data: Credentials; statusCode: number }> {
    return this._http.post('/api/auth/super-admin/login', data, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Credentials; statusCode: number }>) => {
        this.authState.setCredentials(res.body.data);

        return res.body;
      }),
    );
  }

  loginModelAdmin(data: { email: string; password: string }): Observable<{ data: Credentials; statusCode: number }> {
    return this._http.post('/api/auth/model-admin/login', data, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Credentials; statusCode: number }>) => {
        this.authState.setCredentials(res.body.data);

        return res.body;
      }),
    );
  }

  refreshToken(): Observable<{ data: Credentials; statusCode: number }> {
    return this._http.post('/api/auth/refresh-token', { observe: 'response' }).pipe(
      map((res: { data: Credentials; statusCode: number }) => {
        this.authState.setCredentials(res.data);

        return res;
      }),
    );
  }

  logout() {
    this.authState.clearCredentials();
  }

  isLogged() {
    return !(this.authState.credentials === null);
  }

  isAdmin() {
    return this.authState.credentials.payload.role === 'ADMIN';
  }

  isSuperAdmin() {
    return this.authState.credentials.payload.role === 'SUPER_ADMIN';
  }

  isModelAdmin() {
    return this.authState.credentials.payload.role === 'MODEL_ADMIN';
  }
  getId() {
    return this.authState.credentials.payload.id;
  }

  requestPasswordReset(data: { email: string; role: string }): Observable<{ statusCode: number }> {
    return this._http.post('/api/auth/request-reset-password', data, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: any; statusCode: number }>) => {
        return res.body;
      }),
    );
  }

  resetPassword(data: { hash: string; newPassword: string }): Observable<{ statusCode: number; data: { role: string } }> {
    return this._http.put('/api/auth/reset-password', data, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: { role: string }; statusCode: number }>) => {
        return res.body;
      }),
    );
  }

  isUniqueEmailValidator(role: Role, initialValue?: string): AsyncValidatorFn {
    return (formControl: FormControl): Observable<{ [unique: string]: boolean } | null> => {
      if (formControl.value === initialValue) {
        return of(null);
      }

      return timer(250).pipe(
        switchMap(() => this._http.get(`/api/auth/find/${role}/` + formControl.value)),
        map(() => ({ unique: true })),
        catchError(() => of(null)),
      );
    };
  }
}
