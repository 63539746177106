<form [formGroup]="bannerForm">
  <input type="file" #picture hidden (change)="addBanner($event)" />
  <button mat-raised-button color="accent" (click)="picture.click()">
    <mat-icon svgIcon="file_upload"></mat-icon>
    {{ 'load-image' | translate }}...
  </button>
</form>
<div class="img">
  @if (showPicture) {
    <img [src]="apiUrl + banner.picture" />
  }
  @if (file) {
    <cropper [file]="file" [cropWidth]="cropWidth" [cropHeight]="cropHeight" (croppImage)="handleCroppedImage($event)"></cropper>
  }
</div>
