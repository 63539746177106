<div class="contents">
  @if (printableDataList) {
    <div>
      <ngx-datatable
        [rows]="printableDataList.listing"
        [footerHeight]="30"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        (page)="handlePages($event)"
        [limit]="10"
        [externalPaging]="true"
        [count]="printableDataList.total"
        [offset]="offset"
        [messages]="messages$ | async"
      >
        <ngx-datatable-column [name]="'brand' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/management/brand-management']"
              [queryParams]="{ brandName: row?.model?.serie?.brand?.name, exactSearch: true }"
            >
              {{ row?.model?.serie?.brand.name }}
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'serie' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/management/serie-management']"
              [queryParams]="{
                brandName: row?.model?.serie?.brand?.name,
                serieName: row?.model?.serie?.name,
                exactSearch: true,
                showParents: true,
              }"
            >
              {{ row?.model?.serie?.name }}
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'model' | translate" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/management/model-management']"
              [queryParams]="{
                brandName: row?.model?.serie?.brand?.name,
                serieName: row?.model?.serie?.name,
                modelName: row?.model?.name,
                exactSearch: true,
                showParents: true,
              }"
            >
              {{ row?.model?.name }}
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'type' | translate" [maxWidth]="100" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.type == 'WINDOW' ? 'VITRE' : row.type }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [headerClass]="'pieces-cell'"
          [cellClass]="'pieces-cell'"
          [maxWidth]="100"
          [name]="'pieces' | translate"
          [sortable]="false"
          [draggable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a (click)="navigate(row.id)">
              {{ row.pieces?.length }}
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [cellClass]="'item-cell'"
          [maxWidth]="100"
          [name]="'delete' | translate"
          [sortable]="false"
          [draggable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button mat-icon-button (click)="deletePrintableData(row)" color="warn">
              <mat-icon svgIcon="delete"></mat-icon>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  } @else {
    <div>
      <mat-spinner></mat-spinner>
    </div>
  }
</div>
