<mat-toolbar>
  <form (ngSubmit)="search()" [formGroup]="brandFilterForm" novalidate>
    <mat-form-field [subscriptSizing]="'dynamic'">
      <input matInput [placeholder]="'brand' | translate" formControlName="brand" [matAutocomplete]="auto" />
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete">
      @for (option of brands; track option) {
        <mat-option [value]="option.name">
          {{ option.name }}
        </mat-option>
      }
    </mat-autocomplete>
    <button mat-mini-fab color="accent" type="submit">
      <mat-icon svgIcon="search"></mat-icon>
    </button>
  </form>
  <div class="add-btn">
    <a mat-raised-button color="accent" (click)="onAddBrand()">
      <mat-icon svgIcon="note_add"></mat-icon>
      {{ 'add' | translate }}
    </a>
  </div>
</mat-toolbar>
