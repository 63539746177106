<h2>{{ 'banner' | translate }}</h2>
<div class="contents form-contents">
  @if (banner) {
    <banner-form #editForm [banner]="banner" (bannerInfoSubmit)="updateBanner($event)" [resetForm]="resetForm"></banner-form>
    <button
      class="submit"
      mat-raised-button
      color="accent"
      [disabled]="editForm.bannerForm.invalid || !bannerFromForm"
      (click)="editBanner()"
    >
      <mat-icon svgIcon="note_add"></mat-icon>
      {{ 'edit' | translate }}
    </button>
  } @else {
    <banner-form #addForm (bannerInfoSubmit)="updateBanner($event)" [resetForm]="resetForm"></banner-form>
    <button
      class="submit"
      mat-raised-button
      color="accent"
      [disabled]="addForm.bannerForm.invalid || !bannerFromForm"
      (click)="editBanner()"
    >
      <mat-icon svgIcon="note_add"></mat-icon>
      {{ 'add' | translate }}
    </button>
  }
</div>
